.thanks-box {
    width: 100% !important;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background-image: url(../../assets/images/home-banner.jpg);
        background-position-x: center;
        background-repeat: no-repeat;
        z-index: -1;
    }

    .container {
        text-align: center;
        color: white;

        img {
            height: 80px;
            margin-bottom: 20px;

            @media screen and (max-width: 768px) {
                height: 80px;
            }
        }

        .title {
            font-size: 40px;
            font-weight: bold;
            max-width: 700px;
            color: white;

            @media screen and (max-width: 768px) {
                font-size: 30px;
            }
        }

        .form {
            width: 100%;
            max-width: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: auto;
            padding: 30px;
            gap: 20px;
        }

        .options {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            height: 40px;

            .option {
                width: 100%;
                height: 100%;
                text-align: center;
                font-size: 16px;
                background: #00000050;
                border-radius: 6px;
                font-weight: bold;

                &.active {
                    border: 1px solid #43ff91;
                    color: #43ff91;
                }
            }
        }

        input {
            height: 40px;
            width: 100%;
            background: #ffffff20;
            border: 1px solid #ffffff50;
            outline: none;
            border-radius: 100px;
            text-align: center;
            font-size: 16px;
            color: white;
            font-weight: bold;

            &::placeholder {
                color: #ffffffa0;
                font-weight: 400;
            }
        }

        .btn-signup {
            width: 100%;
            height: 40px;
            font-size: 16px;
            font-weight: bold;
            background: #91adff;
            color: black;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.2s all ease-in-out;

            &:hover {
                background: white;
            }

            @media screen and (max-width: 768px) {
                max-width: none;
            }
        }

        .link {
            font-weight: 500;
            text-decoration: underline;
            padding: 10px 20px;
            cursor: pointer;
            background: #000000a0;
            border-radius: 10px;
            margin: 0 5px;
        }
    }
}