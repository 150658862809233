body * {
    font-family: "montserrat" !important;

    a {
        text-decoration: none;
        color: inherit;
    }
}

::-webkit-scrollbar {
    width: 3px;
    height: 3px; // Add this line to make the scrollbar thin for horizontal scrollbar
}

::-webkit-scrollbar-thumb {
    background-color: #ffffff30;
    border-radius: 16px;
}

::-webkit-scrollbar-track {
    background: #ffffff30;
}